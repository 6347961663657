<template>
    <div class="fuel">
        <div class="fuel__icon-container reefer__opacity-5">
            <SvgIcon name="fuel" class="icon fuel__icon" />
        </div>
        <div :style="{height: fuelMax + 'px'}" class="fuel-indicator-bg">
            <div :style="{height: fuel + this.unit}" class="fuel-indicator"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "FuelIndicator",
    props: {
        division: {
            type: Number,
            default: () => { return 10 }
        },
        unit: {
            type: String,
            default: () => { return '%' }
        },
        fuel: {
            type: [String, Number]
        }
    },
    data(){
        return {
            heightDivision: 15
        }
    },
    computed:{
        fuelMax(){
           return  this.division * this.heightDivision
        },
    }
}
</script>

<style scoped>

.fuel {
    display: flex;
    position: absolute;
    right: 5px;
    top: 2px;
}
fuel__icon-container {
     margin-right: 8px;
     width: 24px;
     height: 24px;
 }
.fuel__icon{
    width: 100%;
    height: 100%;
}

.fuel__item{
     border-radius: 5px;
     width: 10px;
     height: 5px;
     background: white;
     margin-bottom: 2px;
     opacity: .55;
 }

.fuel__amount {
     position: absolute;
     display: flex;
     flex-direction: column-reverse;
     bottom: 0;
 }
.fuel__item{
    opacity: 1;
    background: none;
    display: flex;
    align-items: flex-end;
}
.fuel__filled-item {
    background: white;
    border-radius: 5px;
    flex: 1;
}
.fuel-indicator-bg {
    background: url("~@/assets/img/fiuel-bg.svg") bottom;
    overflow: hidden;
    width: 32px;
    display: flex;
    align-items: flex-end;
}
.fuel-indicator{
    background: url("~@/assets/img/fiuel-indicator.svg") bottom;
    width: 32px;
}
@media all and (max-width: 620px) {
    .fuel{
        display: none;
    }
}
</style>