<template>
    <div class="reefer" :class="sizeClass">
        <div class="reefer-full__bg" style="">
            <button v-if="sizeClass" class="button button-close" @click="$emit('update:showMap', !showMap)"></button>
            <div class="reefer__bg">
                <div class="reefer__bg-content">
                    <ul class="reefer__list-info">
                        <template v-for="(item, i) in headListInfo">
                            <li class="reefer__item" :key="i" v-if="!item.hideControl || item.hideControl && item.value" :class="item.errorClass">
                                <div class="reefer__item-icon-container">
                                    <SvgIcon :name="item.icon" class="icon reefer__item-icon"/>
                                </div>
                                <div class="reefer__item-info">
                                    <h3 class="reefer__item-title">{{ $t('Unit.sensors.' + item.title) }}</h3>
                                    <div class="reefer__item-text">{{ item.value }}<span style="margin-left: 5px">{{ item.units }}</span></div>
                                </div>
                            </li>
                        </template>
                    </ul>
                    <div v-if="!showSetPoint" class="reefer__body">
                        <div class="reefer__row reefer__top-info">
                            <div class="reefer__opacity-5">{{ bodyListInfo.setPoint.title }}: {{ bodyListInfo.setPoint.value }} <span> &#176;{{ bodyListInfo.setPoint.units }}</span></div>
                            <FuelIndicator :fuel="bodyListInfo.fuel.value" :division="divisions"/>
                        </div>
                        <div class="reefer__row reefer__row-center reefer__center-info">
                            <div class="reefer__main-value">{{ bodyListInfo.mainTemp.value }} <span class="unit">&#176;{{ bodyListInfo.mainTemp.units }}</span></div>
                        </div>
                        <div class="reefer__row reefer__opacity-5 reefer__bottom-info">
                            <div>
                                <ul class="reefer__bottom-info-list">
                                    <li v-if="bodyListInfo.cycle.value">{{ $t('Unit.sensors.' + bodyListInfo.cycle.title) }}: On</li>
                                    <li v-if="bodyListInfo.highSpeed.value">{{ bodyListInfo.highSpeed.title }}: On</li>
                                    <li>{{ $t('Unit.sensors.' + bodyListInfo.mode.title) }}: {{ bodyListInfo.mode.value }}</li>
                                </ul>
                            </div>
                            <div>{{ $t('Unit.sensors.' + bodyListInfo.fuel.title) }}: {{ bodyListInfo.fuel.value }} {{ bodyListInfo.fuel.units }}</div>
                        </div>
                    </div>
                    <div v-else class="reefer__body set-point">
                        <div class="set-point__text">New setpoint will be:</div>
                        <div class="set-point__val">
                            <span class="set-point__title">{{ temperature }}</span>
                            <span class="unit">&#176;{{ getAppUserUnits.temperature }}</span>
                        </div>
                    </div>
                </div>
                <div class="reefer__control" v-if="!showSetPoint">
                    <ul class="reefer__list-control">
                        <li class="reefer__item-control">
                            <button class="reefer__reefer-button" @click="onShowSetPoint()"> {{ $t('buttons.Set') }} <b> {{ $t('buttons.Point') }}</b></button>
                        </li>
                        <li class="reefer__item-control">
                            <button class="reefer__reefer-button" @click="onStartDefrost">{{ $t('buttons.Start') }} <b>{{ $t('buttons.Defrost') }}</b></button>
                        </li>
                        <li class="reefer__item-control" @click="onStartPti">
                            <button class="reefer__reefer-button">{{ $t('buttons.Start') }} <b>{{ $t('buttons.PTI') }}</b></button>
                        </li>
                    </ul>
                </div>
                <div v-else class="reefer__control reefer__set-point">
                    <ul class="reefer__list-control">
                        <li class="reefer__item-control reefer__item-control-20">
                            <button class="reefer__reefer-button reefer__reefer-button--cancel" @click="showSetPoint=false">Cancel</button>
                        </li>
                        <li class="reefer__item-control reefer__item-control-10">
                            <button class="reefer__reefer-button" @click="removePoints(5)">-5</button>
                        </li>
                        <li class="reefer__item-control reefer__item-control-20">
                            <button class="reefer__reefer-button reefer__reefer-button-remove" @click="removePoints(1)"></button>
                        </li>
                        <li class="reefer__item-control reefer__item-control-20">
                            <button class="reefer__reefer-button reefer__reefer-button-add" @click="addPoints(1)"></button>
                        </li>
                        <li class="reefer__item-control reefer__item-control-10">
                            <button class="reefer__reefer-button" @click="addPoints(5)">+5</button>
                        </li>
                        <li class="reefer__item-control reefer__item-control-20">
                            <button class="reefer__reefer-button reefer__reefer-button--apply" @click="onSetPoint">apply</button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import FuelIndicator from "./reefer_indicator/FuelIndicator";

export default {
    name: "UnitReefer",
    components: {FuelIndicator},
    props:{
        'unitId': [Number, String],
        showMap: {
            type: Boolean
        },
        sizeClass: {
          type: String,
          default: () => { return '' }
        },
        divisions:{
            type: Number,
            default: () => { return 10 }
        },
        unitLmsg: {
            type: Object,
            default: () => { return {} }
        }
    },
    data(){
        return{
            temperature: 0,
            showSetPoint: false,
            headInfoSensors: [
                { field: 'battery',             title: 'Battery voltage',   icon: 'reefer-info-1', hideControl: false},
                { field: 'external_temperature',title: 'External temperature',    icon: 'reefer-info-2', hideControl: false},
                { field: 'supply_discharge',    title: 'Supple air temperature',  icon: 'reefer-info-3', hideControl: false},
                { field: 'evaporator_coil1',    title: 'Evaporator coil',   icon: 'reefer-info-4', hideControl: false},
                { field: 'alarm',               title: 'Alarm',             icon: 'reefer-error',  hideControl: true},
            ],
            bodyInfoSensors:[
                { id: 'setPoint',   field: 'temperature_setpoint',        title: 'Set point',         icon: '' },
                { id: 'mode',       field: 'operating_mode',              title: 'Mode',              icon: '' },
                { id: 'cycle',      field: 'in_continous_operating_mode', title: 'Cycle',             icon: '' },
                { id: 'highSpeed',  field: 'in_high_speed_status',        title: 'High speed',        icon: '' },
                { id: 'fuel',       field: 'fuel_level',                  title: 'Fuel',              icon: '' },
                { id: 'mainTemp',   field: 'return_air1',                 title: 'Return Air',        icon: '' },
            ]
        }
    },
    computed:{
        ...mapGetters([
            "getAppUserUnits",
        ]),
        sensors(){
            let sensors = (this.unitLmsg || {}).sensors || {}
            sensors = {...sensors} //copy!!!
            let alarm = sensors.alarm
            sensors.alarm = {value: alarm, errorClass: {'reefer__item-error': !!alarm, 'hidden': !alarm}}
            return sensors
        },
        headListInfo(){
            return this.headInfoSensors.map( l => {
                let sensor = this.sensors[l.field]
                if(sensor === undefined) return null
                let value = sensor
                if(value === null || (sensor && sensor.value === null)) value = '-'
                if((sensor && sensor.value !== undefined)){
                    return {...l, ...value }
                }
                return {...l, value }
            }).filter(item => item)
        },
        bodyListInfo(){
            return this.bodyInfoSensors.reduce((out,s) => {
                let sensor = this.sensors[s.field]
                if(sensor === undefined) return out
                let value = sensor
                if(value === null || (sensor && sensor.value === null)) value = '-'
                if((sensor && sensor.value !== undefined)){
                    out[s.id] = {...s, ...value }
                } else {
                    out[s.id] = {...s, value}
                }
                return out
            }, {})
        },
    },
    methods:{
        ...mapActions([
            "setSectionInfo",
            "setSectionWidget",
            "sendUnitCmd",
            "setPopup"
        ]),
        addPoints(points){
            this.temperature += points
        },
        removePoints(points){
            this.temperature -= points
        },
        onShowSetPoint(showSetPoint=true){
            this.showSetPoint = showSetPoint
            if(showSetPoint){
                this.temperature = this.bodyListInfo.setPoint.value*1.0
            }
        },
        onSetPoint(){
            //this.showSetPoint = !this.showSetPoint
            let value = this.temperature
            let unit = this.bodyListInfo.setPoint.units
            this.setPopup({
                title: 'Set Temperature Point',
                methods: {
                    ok: () => {
                        this.showSetPoint=false;
                        this.sendCmd('SetTemperature', {value, unit})
                    },
                    //cancel: () => {},
                }
            })
        },
        onStartDefrost(){
            this.setPopup({
                title: 'Start Defrost',
                methods: {
                    ok: () => this.sendCmd('StartDefrost'),
                    //cancel: () => {},
                }
            })
        },
        onStartPti(){
            this.setPopup({
                title: 'Start Pti',
                methods: {
                    ok: () => this.sendCmd('StartPti'),
                    //cancel: () => {},
                }
            })
        },
        sendCmd(cmd, params={}){
            this.sendUnitCmd({ id: this.unitId, cmd, params })
                .then((response) => {
                    //console.log(response)
                    if (response.data && !response.data.error) {
                        this.$toast.success(this.$t("success!"))
                    } else
                    {
                        console.error(response.data);
                        let message = this.$t("error!")
                        if (response.data) {
                            message = response.data.message || response.data.errors || message
                        }
                        this.$toast.error(message)
                    }
                })
                .catch((error) => {
                    // Error
                    if (error.response) {
                        console.error(error.response)
                    } else if (error.request) {
                        console.error(error.request);
                    } else {
                        console.error('Error', error.message);
                    }
                    this.$toast.error(this.$t("error!"))
                    //console.error(error.config);
                })
        },
    }
}
</script>

<style scoped>

</style>